<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
// eslint-disable-next-line vue/require-default-prop, @typescript-eslint/no-unused-vars
const props = defineProps({ item: Object, level: Number });

// Déstructurer directement l'objet pour ne pas rendre l'objet parent réactif
const { name, props: itemProps, render } = props.item;
</script>

<template>
  <template v-if="level > 0">
    <component
      :is="{ name, props: itemProps, render }"
      size="14"
      stroke-width="1.5"
      class="iconClass"
    ></component>
  </template>
  <template v-else>
    <component
      :is="{ name, props: itemProps, render }"
      size="20"
      stroke-width="1.5"
      class="iconClass"
    ></component>
  </template>
</template>
