<script setup lang="ts">
import flagFR from "@/public/images/flag/icon-flag-fr.svg";
import flagEN from "@/public/images/flag/icon-flag-en.svg";
import flagES from "@/public/images/flag/icon-flag-es.svg";
import flagIT from "@/public/images/flag/icon-flag-it.svg";
import type { languageType } from "@/types/HeaderTypes";
const languageDD: languageType[] = [
  { title: "Français", subtext: "French", value: "fr", avatar: flagFR },
  { title: "English", subtext: "UK", value: "en", avatar: flagEN },
  { title: "Español", subtext: "Spain", value: "es", avatar: flagES },
  { title: "Italiano", subtext: "Italia", value: "it", avatar: flagIT },
];
</script>
<template>
  <!-- ---------------------------------------------- -->
  <!-- language DD -->
  <!-- ---------------------------------------------- -->
  <v-menu :close-on-content-click="false" location="bottom">
    <template #activator="{ props }">
      <v-btn icon variant="text" color="primary" v-bind="props">
        <v-avatar size="22">
          <img
            v-if="$i18n.locale === 'fr'"
            :src="flagFR"
            :alt="$i18n.locale"
            width="22"
            height="22"
            class="obj-cover"
          />
          <img
            v-if="$i18n.locale === 'en'"
            :src="flagEN"
            :alt="$i18n.locale"
            width="22"
            height="22"
            class="obj-cover"
          />
          <img
            v-if="$i18n.locale === 'es'"
            :src="flagES"
            :alt="$i18n.locale"
            width="22"
            height="22"
            class="obj-cover"
          />
          <img
            v-if="$i18n.locale === 'it'"
            :src="flagIT"
            :alt="$i18n.locale"
            width="22"
            height="22"
            class="obj-cover"
          />
        </v-avatar>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="200" elevation="10">
      <v-list class="theme-list">
        <v-list-item
          v-for="(item, index) in languageDD"
          :key="index"
          active-color="primary"
          :active="$i18n.locale == item.value"
          class="d-flex align-center"
          @click="() => ($i18n.locale = item.value)"
        >
          <template #prepend>
            <v-avatar size="22">
              <img
                :src="item.avatar"
                :alt="item.avatar"
                width="22"
                height="22"
                class="obj-cover"
              />
            </v-avatar>
          </template>
          <v-list-item-title class="text-subtitle-1 font-weight-regular">
            {{ item.title }}
            <span class="text-disabled text-subtitle-1 pl-1"
              >({{ item.subtext }})</span
            >
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>
