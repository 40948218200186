<template>
  <div>
    <div
      v-for="(toast, i) in toasts"
      :key="i"
      class="custom-dialog"
      :style="{
        top: `${50 + i * height}px !important`,
      }"
    >
      <v-alert
        :type="toast.type"
        absolute
        variant="outlined"
        :color="toast.type"
        border="start"
        elevation="2"
        width="500"
        transition="scroll-x-reverse-transition"
      >
        {{ toast.text }}
        <template #close>
          <v-btn icon x-small @click="close(i)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-alert>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useToastStore } from "~/stores/toast";

const height = 70;
const toastStore = useToastStore();
const toasts = computed(() => toastStore.messages);

const close = (i) => {
  toastStore.removeToast(i);
};
</script>

<style scoped>
.custom-dialog {
  right: 10px !important;
  background-color: white !important;
  border-radius: 10px !important;
  position: fixed;
  z-index: 9999;
}
</style>
