<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { useIataStore } from "@/stores/iata";

const keycloak = useNuxtApp().$keycloak;
const iata: any = useIataStore();
const auth: any = useAuthStore();
const itemGroups: any = ref([]);
const selectGroupId = ref(null);
const showDialog = ref(false);
const closeOnContentClick = ref(false);
const isLoading = ref(false);

const confirmSelection = () => {
  // La logique pour gérer la sélection, par exemple, afficher une boîte de dialogue
  showDialog.value = true;
};

const getGroupsImpersonation = async () => {
  try {
    isLoading.value = true; // Définir le chargement sur true
    const [responseGroups] = await Promise.all([
      axios.get(`/dashboard/api/users_keycloak/read/all/subgroups`),
    ]);
    itemGroups.value = responseGroups.data.values;
  } catch (error) {
    console.error("Erreur lors de la récupération des données groupes:", error);
  } finally {
    isLoading.value = false; // Définir le chargement sur false
  }
};

const FormatTextGroup = (item: any) => {
  return `${item.name} (${item.type}) - ${item.GIE}`;
};

const FormatTextValueGroup = () => {
  if (!selectGroupId.value) return null;
  const group = itemGroups.value.find(
    (group: any) => group.id === selectGroupId.value
  );
  return `${group.name}`;
};

const getTokenImpersonation = async () => {
  try {
    isLoading.value = true; // Définir le chargement sur true
    const [responseToken] = await Promise.all([
      axios.post(`/dashboard/api/users_keycloak/impersonation/change/group`, {
        groupId: selectGroupId.value,
      }),
    ]);
    if (responseToken.data.values) {
      showDialog.value = false;
      iata.SET_IATA(null);
      await navigateTo("/booking/flight");
      await keycloak.initKeycloak();
    }
  } catch (error) {
    console.error("Erreur lors de la récupération du token:", error);
  } finally {
    isLoading.value = false; // Définir le chargement sur false
  }
};

const BackUp = async () => {
  try {
    isLoading.value = true; // Définir le chargement sur true
    const [response] = await Promise.all([
      axios.post(`/dashboard/api/users_keycloak/impersonation/back/up`),
    ]);
    showDialog.value = false;
    iata.SET_IATA(null);

    if (response.data.values) {
      await navigateTo("/booking/flight");
      await keycloak.initKeycloak();
    }
  } catch (error) {
    console.error("Erreur lors de la récupération du token:", error);
  } finally {
    isLoading.value = false; // Définir le chargement sur false
  }
};

const cancelImpersonation = () => {
  showDialog.value = false;
  selectGroupId.value = null;
};

const handleMenuClick = async () => {
  // ça charge que une fois !
  if (itemGroups.value.length < 1) {
    await getGroupsImpersonation();
  }
};

watch(selectGroupId, (newValue) => {
  if (newValue) {
    confirmSelection();
  }
});

// watch(() => auth.groups, (newVal) => {
//     if (newVal && newVal[0] === 'Metis') {
//         getGroupsImpersonation();
//     }
// }, { immediate: true });
</script>

<template>
  <!-- Boîte de dialogue pour confirmation -->
  <v-dialog v-model="showDialog" persistent max-width="400px">
    <v-card>
      <v-card-title class="headline">Confirmation</v-card-title>
      <v-card-text>
        Voulez-vous voir en tant que : {{ FormatTextValueGroup() }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled:="isLoading"
          color="green darken-1"
          text
          @click="getTokenImpersonation"
          >Confirmer</v-btn
        >
        <v-btn
          :disabled:="isLoading"
          color="red darken-1"
          text
          @click="cancelImpersonation"
          >Annuler</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-menu
    :close-on-content-click="closeOnContentClick"
    @click="handleMenuClick"
  >
    <template #activator="{ props }">
      <v-btn
        v-if="
          auth.groups &&
          auth.role_impersonate === 'role_impersonate' &&
          !auth.userDetails.previous_id_group
        "
        :disabled="isLoading"
        icon
        variant="text"
        class="custom-hover-primary ml-2"
        size="small"
        color="primary"
        v-bind="props"
        flat
      >
        <EyeIcon v-if="!isLoading" size="22" />
        <v-progress-circular
          v-if="isLoading"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </template>

    <v-sheet width="360" elevation="10" rounded="md">
      <v-col cols="12">
        <v-card flat>
          <v-autocomplete
            v-model="selectGroupId"
            :items="itemGroups"
            :item-title="FormatTextGroup"
            item-value="id"
            color="primary"
            variant="outlined"
            hide-details="auto"
            clearable
          ></v-autocomplete>
        </v-card>
      </v-col>
    </v-sheet>
  </v-menu>

  <v-btn
    v-if="auth.userDetails && auth.userDetails.previous_id_group"
    flat
    color="error"
    variant="flat"
    :disabled:="isLoading"
    @click="BackUp()"
  >
    <v-progress-circular
      v-if="isLoading"
      :size="18"
      indeterminate
    ></v-progress-circular>
    <EyeIcon
      v-if="!isLoading"
      stroke-width="1.5"
      size="18"
      class="mr-2"
    />Revenir sur mon groupe
  </v-btn>
</template>
