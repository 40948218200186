<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref, watch } from "vue";
import { Menu2Icon } from "vue-tabler-icons";
import { useCustomizerStore } from "@/stores/customizer";

// Icon Imports
const customizer = useCustomizerStore();
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
watch(priority, (newPriority) => {
  priority.value = newPriority;
});

const logOut = () => {
  useNuxtApp().$keycloak.doLogout();
};

const envCheck = () => {
  const runtimeConfig = useRuntimeConfig();
  return runtimeConfig.env.includes("Preprod");
};
</script>

<template>
  <v-app-bar elevation="10" :priority="priority" height="64" color="primary">
    <div
      :class="
        customizer.boxed
          ? 'maxWidth v-toolbar__content px-lg-0 px-0'
          : 'v-toolbar__content px-6'
      "
    >
      <h1 v-if="envCheck()" style="color: red">Preprod</h1>
      <div
        class="pr-4 d-sm-flex d-none"
        style="cursor: pointer"
        @click="navigateTo('/booking/flight')"
      >
        <LcFullLogo />
      </div>
      <div
        class="pr-2 pt-2 d-sm-none d-flex mr-2"
        style="cursor: pointer"
        @click="navigateTo('/booking/flight')"
      >
        <LcFullLogoIcon />
      </div>
      <v-btn
        class="hidden-lg-and-up"
        icon
        variant="text"
        rounded="sm"
        size="small"
        @click.stop="customizer.SET_SIDEBAR_DRAWER"
      >
        <Menu2Icon size="25" />
      </v-btn>

      <LcFullHorizontalHeaderImpersonateView />

      <!-- ------------------------------------------------>
      <!-- Search part -->
      <!-- ------------------------------------------------>

      <!---/Search part -->
      <v-spacer />
      <!-- ---------------------------------------------- -->
      <!---IATA -->
      <!-- ---------------------------------------------- -->
      <LcFullHorizontalHeaderIataDisplay />
      <!-- ---------------------------------------------- -->
      <!-- translate -->
      <!-- ---------------------------------------------- -->
      <LcFullVerticalHeaderLanguageDD />

      <div class="ml-auto profile-logout">
        <v-btn
          variant="text"
          icon
          rounded="md"
          color="white"
          to="/"
          @click="logOut()"
        >
          <PowerIcon size="22" />
          <v-tooltip activator="parent" location="top">{{$t(`components-lc-Full-horizontal-header-index.logout`)}}</v-tooltip>
        </v-btn>
      </div>

      <!-- ---------------------------------------------- -->
      <!-- User Profile -->
      <!-- ---------------------------------------------- -->
      <div class="ml-3"></div>
    </div>
  </v-app-bar>
</template>
