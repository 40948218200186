<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  items: {
    type: Array as any,
    required: true,
  },
});
const model = ref(false);

const show = () => {
  model.value = true;
};

const close = () => {
  model.value = false;
};
const formatNewLines = (description) => {
  return description.replace(/\n/g, "<br>");
};
</script>

<template>
  <div>
    <v-btn
      class="pulse-animation"
      size="medium"
      icon
      elevation="0"
      @click="show()"
    >
      <v-icon color="error">mdi-alert</v-icon>
    </v-btn>
    <v-dialog v-model="model" transition="fade-transition" max-width="1000">
      <v-card class="overflow-auto">
        <v-card-title>
          <v-row class="pa-3" align="center" justify="space-between">
            <h3 class="text-h4">{{ $t(`components-AlertsList.list-of-alerts`) }}</h3>
            <v-btn icon="mdi-close" elevation="0" @click="close()"> </v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3">
          <v-list-item v-for="(item, i) in items" :key="i" class="pa-3">
            <v-row align="center" :class="i !== items.length - 1 ? 'pb-5' : ''">
              <v-col cols="1" align="center">
                <v-icon
                  :color="item.isBlocking ? 'error' : 'warning'"
                  size="large"
                >
                  {{ item.isBlocking ? "mdi-alert" : "mdi-alert-circle" }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <div
                  class="readonly-textarea"
                  v-html="formatNewLines(item.description)"
                ></div>
              </v-col>
            </v-row>
            <v-divider v-if="i !== items.length - 1"></v-divider>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.readonly-textarea {
  overflow-y: auto; /* Ajoute une barre de défilement si nécessaire */
  background-color: #f5f5f5; /* Couleur de fond similaire à celle d'un textarea */
  border: 1px solid #ccc; /* Bordure pour ressembler à un textarea */
  padding: 10px; /* Padding intérieur comme un textarea */
  border-radius: 4px; /* Bordures arrondies */
  font-family: monospace; /* Police de caractères pour l'affichage du texte */
  font-size: 14px; /* Taille de la police */
  min-height: 100px; /* Hauteur minimale */
}
.pulse-animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
